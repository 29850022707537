body {
	margin: 0 !important;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
	  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
	  sans-serif !important;
	-webkit-font-smoothing: antialiased !important;
	-moz-osx-font-smoothing: grayscale !important;
  }
  
  code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
	  monospace !important;
  }
  
  :root {
	--brand-color: rgb(19, 196, 255, 1) !important;
	--red: rgb(255, 91, 132) !important;
	--brand-color-fade: rgb(19, 196, 255, 0.3) !important;
	--green: rgb(44, 215, 152, 1) !important;
	--green-fade: rgb(44, 215, 152, 0.3) !important;
	--bg-danger: rgba(255, 91, 132, 0.14) !important;
	--bg-success: rgba(44, 215, 152, 0.17) !important;
	--bg-primary: rgba(19, 196, 255, 0.17) !important;
	--bg-warning: rgba(213, 196, 63, 0.19) !important;
	--border: rgba(0, 0, 0, 0.07) !important;
	--light-gray-bg: rgba(0, 0, 0, 0.03) !important;
	--light-gray-bg2: rgba(0, 0, 0, 0.07) !important;
	--text-gray-lighter: #aaaaaa !important;
	--text-gray-light: #444 !important;
	--main-dark: #202124 !important;
	--secondary-dark: rgba(255, 255, 255, 0.02) !important;
	--gray-dark: rgba(255, 255, 255, 0.3) !important;
	--text-dark: rgba(255, 255, 255, 0.9) !important;
	--hover-dark: rgba(255, 255, 255, 0.5) !important;
	--border-dark: rgba(255, 255, 255, 0.04) !important;
	--main-shadow: 0 5px 15px rgba(0, 0, 0, 0.06);
  }
  
  * {
	box-sizing: border-box !important;
  }
  
  body,
  html,
  #root,
  #root > div,
  #root > div > div {
	height: 100% !important;
	position: relative !important;
	font-family: 'DM Sans', sans-serif !important;
	font-size: 14px !important;
	color: var(--text-gray-light) !important;
  }
  a,
  a:active,
  a:focus,
  a:hover {
	color: var(--brand-color) !important;
	text-decoration: none !important;
  }
  *:focus {
	outline: none !important;
  }
  
  .page-content {
	min-height: 100% !important;
	padding: 100px 0 !important;
  }
  .container {
	max-width: 1300px !important;
	padding: 0 20px !important;
  }
  
  #root .text-danger {
	color: var(--red) !important;
  }
  
  .text-default {
	color: var(--text-gray-light) !important;
  }
  
  /* HEADER */
  
  header {
	position: fixed !important;
	width: 100% !important;
	background-color: #fff !important;
	box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.06) !important;
	z-index: 999 !important;
  }
  
  .navbar {
	padding: 20px 0 !important;
  }
  
  .navbar-nav {
	flex-wrap: wrap !important;
	justify-content: center !important;
  }
  .nav-link {
	color: var(--text-gray-light) !important;
  }
  .navbar-brand {
	padding: 0 !important;
  }
  .navbar-brand img {
	height: 40px !important;
	width: auto !important;
  }
  .nav-item {
	padding: 0 !important;
	margin: 0 5px !important;
	font-weight: 500 !important;
  }
  .nav-link {
	transition: all 0.3s ease !important;
  }
  .navbar-light .navbar-nav .active > .nav-link,
  .navbar-light .navbar-nav .nav-link,
  .navbar-light .navbar-nav .nav-link:hover {
	position: relative !important;
	color: var(--text-gray-light) !important;
  }
  
  button.navbar-toggler {
	border: none !important;
  }
  button.navbar-toggler:focus {
	outline: none !important;
  }
 .navbar-light .navbar-toggler-icon{
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M3 4h18v2H3V4zm0 7h18v2H3v-2zm0 7h18v2H3v-2z' fill='rgba(0,0,0,1)'/%3E%3C/svg%3E") !important;
  }
  .profile-item {
	height: 40px !important;
  }
  
  .profile {
	margin-left: 20px !important;
  }
  
  .profile-img {
	position: relative !important;
	height: 40px !important;
	width: 40px !important;
	border-radius: 8px !important;
	object-fit: cover !important;
	cursor: pointer !important;
  }
  .recipeuploaded-img {
	position: relative !important;
	height: 80px !important;
	width: 80px !important;
	border-radius: 8px !important;
	object-fit: cover !important;
  }

  
  .student-img {
	position: relative !important;
	height: 40px !important;
	width: 40px !important;
	border-radius: 999px !important;
	margin-right: 15px;
	object-fit: cover !important;
  }
  .teacher-img {
	border-radius: 12px;
  }
  .dropdown-menu {
	border-radius: 0 0 10px 10px !important;
  }
  .navbar-nav .dropdown-menu {
	border: none !important;
	padding: 30px !important;
	box-shadow: 0 30px 30px 0 rgba(0, 0, 0, 0.07) !important;
  }
  .dropdown-toggle::after {
	display: none !important;
	margin-left: 0 !important;
	vertical-align: 0 !important;
	content: '' !important;
	border-top: 0 !important;
	border-bottom: 0 !important;
  }
  
  .dropdown-item {
	padding: 8px 1.1rem !important;
	font-size: 14px !important;
	white-space: normal !important;
	transition: all 0.2s ease;
  }
  
  .dropdown-item:hover {
	background-color: transparent !important;
	color: var(--brand-color) !important;
  }
  
  .drop-down {
	cursor: pointer !important;
  }
  .recipeimg {
	cursor: pointer !important;
  }
  .cursorpointer {
	cursor: pointer !important;
  }
  .profile-item {
	height: auto !important;
  }
  
  .profile-item .dropdown-menu {
	font-size: 0.9rem !important;
	box-shadow: var(--main-shadow) !important;
	border-radius: 0 0 0.5rem 0.5rem !important;
	overflow: hidden !important;
	margin-top: 22px !important;
	border: none !important;
  }
  .profile-item .dropdown-menu .dropdown-item {
	background-color: #fff;
	transition: none;
  }
  .profile-item .dropdown-menu .dropdown-item:hover {
	background-color: rgba(0, 50, 125, 0.035) !important;
  }
  .profile-item .dropdown-menu .dropdown-item:active {
	background-color: rgba(0, 50, 125, 0.035) !important;
	color: unset !important;
  }
  .logout {
	color: #fd2762 !important;
  }
  
  /* MAIN */
  
  .breadcrumb {
	padding: 0 0 20px 0 !important;
	background-color: transparent !important;
	margin-bottom: 0 !important;
  }
  .breadcrumb-item {
	transition: all 0.3s ease !important;
  }
  .breadcrumb-item a,
  .breadcrumb-item + .breadcrumb-item::before {
	color: var(--text-gray-lighter) !important;
	font-size: 12px !important;
	font-weight: 500 !important;
  }
  
  .breadcrumb-item.active,
  .breadcrumb-item.active a {
	color: var(--brand-color) !important;
  }
  .breadcrumb-item:hover a {
	color: var(--brand-color) !important;
	text-decoration: none !important;
  }
  .content-title {
	font-size: 18px !important;
	font-weight: 500 !important;
	padding-bottom: 15px !important;
  }
  
  .form-group {
	margin-bottom: 20px !important;
  }
  
  .form-group input,
  .file-input-container input + label,
  .multi-select .dropdown-heading {
	/* height: 40px !important; */
	border-radius: 8px !important;
	background-color: var(--light-gray-bg) !important;
	margin-bottom: 0 !important;
	border: none;
	transition: all 0.3s ease !important;
  }
  
  .dropdown-heading-dropdown-arrow > span {
	border: none !important;
  }
  .dropdown-heading-value {
	padding-right: 20px !important;
  }
  .dropdown-heading-value > span {
	line-height: 40px;
	color: var(--text-gray-ligh) !important;
  }
  .form-group textarea {
	resize: none;
	height: 128px !important;
	border-radius: 8px !important;
	background-color: var(--light-gray-bg) !important;
	margin-bottom: 0 !important;
	border: none !important;
	transition: all 0.3s ease !important;
  }
  .form-group textarea.single-line {
	height: 40px !important;
	line-height: 28px;
  }
  
  .file-input-container input + label {
	border: 1px solid transparent !important;
  }
  .file-input-container {
	height: 40px !important;
  }
  .file-input-container,
  .file-input-container input.form-control:active {
	border: none !important;
	background-color: transparent !important;
  }
  
  .form-control:focus,
  .form-control:active,
  input[type='file']:active,
  input[type='file']:focus,
  .form-select:focus,
  .form-select:focus {
	box-shadow: 0 0 0 1px var(--brand-color) !important;
	border: none !important;
	background-color: var(--light-gray-bg) !important;
  }
  .form-select {
	position: relative !important;
  }
  .form-select select,
  .react-datepicker-wrapper input,
  .dropdown-heading {
	-webkit-appearance: none !important;
	border: none !important;
	border-radius: 8px !important;
	background: var(--light-gray-bg) !important;
	height: 40px !important;
  }
  
  .form-select:after {
	font-family: remixicon !important;
	content: '\EA4E' !important;
	color: #cecece;
	font-size: 18px;
	position: absolute !important;
	top: 8px !important;
	right: 6px !important;
	pointer-events: none !important;
  }
  .was-validated .form-select:after {
	background: transparent !important;
  }
  
  /* date picker */
  .react-datepicker,
  .react-datepicker-popper {
	font-family: 'DM Sans', sans-serif !important;
	z-index: 9999;
  }
  .popover-inner,
  .react-datepicker {
	box-shadow: var(--main-shadow);
  }
  
  .react-datepicker-popper,
  .react-datepicker {
	width: 100% !important;
  }
  .react-datepicker__day--today {
	color: var(--text-gray-light) !important;
	font-weight: 500 !important;
	background-color: var(--light-gray-bg) !important;
  }
  
  .react-datepicker__day--today.react-datepicker__day--selected {
	color: #fff !important;
  }
  
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
	width: 12% !important;
	line-height: 1.9rem !important;
  }
  
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
	font-weight: 500 !important;
  }
  
  .react-datepicker__month-container {
	width: 100% !important;
  }
  .react-datepicker-wrapper {
	display: block !important;
  }
  
  .react-datepicker__header {
	background-color: transparent !important;
	border-bottom: none !important;
  }
  
  .react-datepicker__day--selected:hover,
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-range:hover,
  .react-datepicker__month-text--selected:hover,
  .react-datepicker__month-text--in-selecting-range:hover,
  .react-datepicker__month-text--in-range:hover,
  .react-datepicker__quarter-text--selected:hover,
  .react-datepicker__quarter-text--in-selecting-range:hover,
  .react-datepicker__quarter-text--in-range:hover {
	background-color: var(--brand-color) !important;
  }
  
  .react-datepicker-popper[data-placement^='bottom'].react-datepicker__triangle,
  .react-datepicker {
	border: 1px solid var(--text-gray-lighter) !important;
  }
  
  .pagination-select {
	display: flex !important;
	align-items: center !important;
	margin-right: 30px !important;
	padding: 0 19px !important;
  }
  .pagination-select label {
	margin-bottom: 0 !important;
	margin-right: 10px !important;
  }
  
  .pagination-select select {
	width: 60px !important;
	border: none !important;
	background-color: transparent !important;
  }
  
  .pagination-select select:focus {
	border: none !important;
	box-shadow: none !important;
	background-color: transparent !important;
	outline: 0 !important;
  }
  
  /* Buttons */
  
  .btn {
	height: 40px !important;
	font-weight: 500 !important;
	padding: 0px 16px !important;
	border-radius: 8px !important;
	line-height: 40px !important;
	border: none !important;
	font-size: 14px !important;
	transition: all 0.3s ease !important;
  }
  button.btn-primary {
	background-color: var(--brand-color) !important;
  }
  /* button.btn-secondary {
	color: var(--text-gray-light) !important;
	background-color: transparent !important;
  } */
  
  button.btn-success {
	background-color: var(--brand-color) !important;
  }
  
  .btn:focus,
  .btn:active,
  .btn:hover {
	border: none !important;
	box-shadow: none !important;
	outline: none !important;
  }
  
  button.btn-primary:focus,
  button.btn-primary:active,
  button.btn-primary:hover {
	background-color: var(--brand-color) !important;
	box-shadow: 0 5px 10px 0 var(--brand-color-fade) !important;
  }
  /* button.btn-secondary:focus,
  button.btn-secondary:active,
  button.btn-secondary:hover {
	background-color: var(--light-gray-bg) !important;
	color: #444 !important;
	box-shadow: 0 5px 10px 0 rgb(235, 235, 235) !important;
  } */
  
  button.btn-success:focus,
  button.btn-success:active,
  button.btn-success:hover {
	background-color: var(--brand-color) !important;
	box-shadow: 0 5px 10px 0 var(--brand-color-fade) !important;
  }
  
  /* Footer */
  
  footer {
	margin-top: -80px !important;
	box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.06) !important;
  }
  .footer-logo {
	height: 35px !important;
	width: auto !important;
  }
  .copyright {
	color: var(--text-gray-lighter) !important;
	font-size: 12px !important;
	text-align: center !important;
	padding: 31px 0 !important;
  }
  
  /* File Input */
  
  input[type='file'] {
	position: relative;
	width: 100%;
	padding: 7px 2px !important;
	color: var(--text-gray-light);
  }
  
  input[type='file']::-webkit-file-upload-button {
	padding: 0px !important;
	visibility: hidden;
	width: 0 !important;
	text-indent: 0 !important;
  }
  
  input[type='file']::before {
	content: '\EA84';
	font-family: remixicon !important;
	color: var(--text-gray-lighter);
	position: absolute;
	right: 11px;
	top: 9px;
	width: 15px;
	height: 14px;
	height: 100%;
	display: inline-block;
	border-radius: 8px;
	outline: none;
	white-space: nowrap;
	-webkit-user-select: none;
	cursor: pointer;
	text-shadow: 1px 1px #fff;
	font-weight: 500;
	font-size: 16px;
  }
  
  input[type='file']:hover::before {
	border-color: black;
  }
  
  .file-name {
	max-width: calc(100% - 100px) !important;
	white-space: nowrap !important;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
	display: block !important;
	line-height: 38px !important;
	margin-left: 14px !important;
	white-space: nowrap !important;
  }
  
  .file-selected .label-doc {
	right: 40px !important;
  }
  .file-selected .label-remove {
	z-index: 3 !important;
	display: block !important;
	border-radius: 8px !important;
	background-color: rgba(0, 0, 0, 0.03) !important;
  }
  
  .label-doc::before {
	content: '\EA84';
	font-family: remixicon !important;
  }
  
  /* .label-remove {
	background-image: url(././images/times-solid.svg) !important;
	display: none !important;
	background-size: auto 14px !important;
	background-position: center !important;
	background-repeat: no-repeat !important;
  } */
  
  /* multi select */
  .item-renderer {
	display: flex;
	align-items: center;
  }
  .item-renderer input {
	height: auto !important;
  }
  
  /* Login */
  
  .login-container {
	position: absolute !important;
	top: 40% !important;
	left: 50% !important;
	transform: translate(-50%, -50%) !important;
	width: 350px !important;
	padding: 30px !important;
	border-radius: 10px !important;
	background-color: rgba(255, 255, 255, 0.03) !important;
	box-shadow: 0 0 0 1px var(--border) !important;
  }
  
  .login-container .logo {
	display: block !important;
	margin: auto !important;
	margin-bottom: 30px !important;
	height: 50px !important;
	width: auto !important;
  }
  
  .remember-me,
  .forgot-pw,
  .forgot-pw:active,
  .forgot-pw:focus,
  .forgot-pw:hover {
	text-decoration: none !important;
	color: #999999 !important;
	font-size: 13px !important;
	display: block !important;
	font-weight: 500 !important;
  }
  
  /* Custom CheckBox */
  
  .form-csCheck,
  .form-csCheck input:checked ~ .form-csCheck-checkmark:after {
	display: block !important;
  }
  .form-info {
	padding: 10px 0 !important;
	margin-bottom: 1rem !important;
  }
  
  .login-container .form-csCheck {
	margin: 15px 0 !important;
  }
  .form-csCheck {
	position: relative !important;
	padding-left: 32px !important;
	margin: 0 !important;
	cursor: pointer !important;
	-webkit-user-select: none !important;
	-moz-user-select: none !important;
	-ms-user-select: none !important;
	user-select: none !important;
	line-height: 21px !important;
	width: 100% !important;
	max-width: unset !important;
  }
  .form-csCheck input {
	position: absolute !important;
	opacity: 0 !important;
	cursor: pointer !important;
	height: 0 !important;
	width: 0 !important;
  }
  .form-csCheck input:checked ~ .form-csCheck-checkmark {
	background-color: var(--brand-color) !important;
	box-shadow: 0 5px 10px 0 var(--brand-color-fade) !important;
  }
  .form-csCheck-checkmark {
	-webkit-transition: all 0.1s ease !important;
	transition: all 0.1s ease !important;
	position: absolute !important;
	background-color: rgba(0, 0, 0, 0.06) !important;
	top: 2px !important;
	left: 0 !important;
	height: 18px !important;
	/* width: 18px !important; */
	border-radius: 5px !important;
  }
  .form-csCheck-checkmark:after {
	color: #fff;
	font-weight: bold;
	font-family: remixicon !important;
	content: '\EB7B' !important;
	position: absolute !important;
	display: none !important;
	left: 3px !important;
	top: -1px !important;
  }
  
  /*  Table */
  
  .table {
	height: auto !important;
	display: -webkit-box !important;
	display: flex !important;
	-webkit-box-orient: vertical !important;
	-webkit-box-direction: normal !important;
	flex-direction: column !important;
	-webkit-box-pack: justify !important;
	justify-content: space-between !important;
	margin-bottom: 0 !important;
  }
  .table-responsive {
	overflow-x: auto !important;
  }
  table.table {
	height: auto !important;
	display: block !important;
	margin-bottom: 0 !important;
  }
  .table-wrapper,
  .card {
	border: 1px solid var(--border) !important;
	border-radius: 10px !important;
  }
  .accordion .card-header,
  .accordion .card {
	border: none !important;
	border-radius: 10px !important;
	background-color: var(--light-gray-bg) !important;
  }
  .accordion .card-header {
	background-color: transparent !important;
  }
  
  .table .thead,
  .table thead {
	padding-top: 10px !important;
	border: none !important;
  }
  .table .thead .td,
  .table thead td,
  .table .thead .th,
  .table thead th {
	font-weight: 500 !important;
	border: none !important;
  }
  .table .td,
  .table .th,
  .table td,
  .table th {
	border-top: none !important;
  }
  .table .tr {
	display: -webkit-box !important;
	display: flex !important;
	border: none !important;
	padding: 5px 15px !important;
  }
  
  /* colored row tables */
  .table .tr.bg-danger,
  .table tr.bg-danger,
  .table .tr.bg-danger:nth-child(2n),
  .table tr.bg-danger:nth-child(2n) {
	background-color: var(--bg-danger) !important;
  }
  .table .tr.bg-danger .td,
  .table tr.bg-danger td,
  .table .tr.bg-danger:nth-child(2n) .td,
  .table tr.bg-danger:nth-child(2n) td {
	border-top: none;
  }
  .table .tr.bg-warning,
  .table tr.bg-warning,
  .table .tr.bg-warning:nth-child(2n),
  .table tr.bg-warning:nth-child(2n) {
	background-color: var(--bg-warning) !important;
  }
  .table .tr.bg-warning .td,
  .table tr.bg-warning td,
  .table .tr.bg-warning:nth-child(2n) .td,
  .table tr.bg-warning:nth-child(2n) td {
	border-top: none;
  }
  .table .tr.bg-success,
  .table tr.bg-success,
  .table .tr.bg-success:nth-child(2n),
  .table tr.bg-success:nth-child(2n) {
	background-color: var(--bg-success) !important;
  }
  .table .tr.bg-success .td,
  .table tr.bg-success td,
  .table .tr.bg-success:nth-child(2n) .td,
  .table tr.bg-success:nth-child(2n) td {
	border-top: none;
  }
  .table .tr.bg-primary,
  .table tr.bg-primary,
  .table .tr.bg-primary:nth-child(2n),
  .table tr.bg-primary:nth-child(2n) {
	background-color: var(--bg-primary) !important;
  }
  .table .tr.bg-primary .td,
  .table tr.bg-primary td,
  .table .tr.bg-primary:nth-child(2n) .td,
  .table tr.bg-primary:nth-child(2n) td {
	border-top: none;
  }
  
  .table .tr.bold,
  .table tr.bold {
	font-weight: 500 !important;
  }
  .table .tr:nth-child(2n),
  .table tr:nth-child(2n) {
	background-color: var(--light-gray-bg) !important;
  }
  
  .table .tr,
  .table tr,
  .table .tbody,
  .table tbody,
  .table .thead,
  .table thead {
	min-width: fit-content;
  }
  .table .tr .td,
  .table .tr .th {
	-webkit-box-flex: 1 !important;
	flex: 1 1 0 !important;
	display: flex;
	align-items: center;
	padding: 4px !important;
	border: none !important;
  }
  .table tr td,
  .table thead th {
	vertical-align: middle !important;
  }
  /* .table .tr .td:first-child {
	  min-width: 40% !important;
  } */
  .table .tfoot,
  .table tfoot {
	border: none !important;
	border-top: 1px solid rgba(0, 0, 0, 0.06) !important;
	padding: 0.63rem 0 !important;
  }
  .table .tfoot .tr .td:first-child,
  .table .tfoot tr td:first-child {
	font-weight: 500 !important;
  }
  .table .tr .td a,
  .table tr td a {
	cursor: pointer;
  }
  .table .td .btn,
  .table td .btn {
	height: 34px !important;
	line-height: 34px !important;
  }
  
  .pagination {
	padding: 0.63rem 10px !important;
	border-top: 1px solid rgba(0, 0, 0, 0.06) !important;
	border-radius: 0 !important;
	margin-bottom: 0 !important;
  }
  
  .pagination .page-item {
	width: 30px !important;
	height: 30px !important;
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
	transition: all 0.3s ease !important;
  }
  
  .pagination .page-link {
	cursor: pointer;
	border: none !important;
	background-color: transparent !important;
	color: var(--text-gray-light) !important;
	padding: 0 !important;
  }
  
  .page-item.count {
	width: 80px !important;
  }
  .page-item:hover .page-link {
	background: unset !important;
  }
  
  .page-item:hover {
	opacity: 0.7 !important;
  }
  .page-item.passive:hover,
  .page-item.count:hover {
	opacity: 1 !important;
  }
  .page-item.passive .page-link {
	opacity: 0.3 !important;
  }
  .page-item .page-link:focus {
	outline: none !important;
	box-shadow: none !important;
  }
  
  .page-item.active {
	background: var(--brand-color) !important;
	border-radius: 8px !important;
  }
  .page-item.active .page-link {
	background-color: transparent !important;
	border-radius: 5px !important;
	color: var(--text-gray-light) !important;
  }
  
  .pagination .page-link span {
	display: block !important;
	position: relative !important;
	width: 18px !important;
	height: 18px !important;
	background-size: contain !important;
	background-position: center !important;
	background-repeat: no-repeat !important;
  }
  .pagination .first span::before {
	font-family: remixicon !important;
	content: '\F13F';
  }
  .pagination .prev span {
	transform: scale(-1, 1);
	transform-origin: center;
  }
  .pagination .prev span::before {
	font-family: remixicon !important;
	content: '\F00A';
  }
  
  .pagination .next span::before {
	font-family: remixicon !important;
	content: '\F00A';
  }
  
  .pagination .last span::before {
	font-family: remixicon !important;
	content: '\F143';
  }
  .dropdown .nav-item {
	list-style: none;
  }
  
  @media (min-width: 1201px) {
	.navbar-light .navbar-nav .nav-link::before {
	  content: '' !important;
	  position: absolute !important;
	  left: 0 !important;
	  bottom: -22px !important;
	  width: 100% !important;
	  height: 2px !important;
	  border-radius: 999px !important;
	  background-color: var(--text-gray-light) !important;
	  opacity: 0 !important;
	  transform: scaleX(0.5) !important;
	  transition: all 0.3s ease !important;
	  transform-origin: center !important;
	}
  
	header .profile-item .nav-link::before {
	  content: '' !important;
	  position: absolute !important;
	  left: 0 !important;
	  bottom: -22px !important;
	  width: 100% !important;
	  height: 2px !important;
	  border-radius: 999px !important;
	  background-color: var(--text-gray-light) !important;
	  opacity: 0 !important;
	  transform: scaleX(0.5) !important;
	  transition: all 0.3s ease !important;
	}
  
	.navbar-light .navbar-nav .nav-link:hover::before,
	header .profile-item .nav-link:hover::before,
	.navbar-light .navbar-nav .nav-link:focus::before,
	header .profile-item .nav-link:focus::before {
	  transform: scaleX(1) !important ;
	  opacity: 1 !important;
	}
  }
  @media (max-width: 1199px) {
	.dropdown-menu {
	  border-radius: 0 0 0 0 !important;
	}
	.navbar {
	  padding: 0 !important;
	}
	.navbar-toggler {
	  padding: 0 !important;
	  padding-right: 20px !important;
	}
	.navbar-brand {
	  height: 80px !important;
	  padding: 20px 0 20px 20px !important;
	}
	.navbar-collapse {
	  max-height: calc(100vh - 80px) !important;
	  overflow-y: scroll !important;
	  overflow-x: hidden !important;
	}
  
	.navbar-nav {
	  padding: 20px !important;
	}
  
	.navbar-light .navbar-nav .nav-link::before {
	  bottom: 0px !important;
	}
	.profile {
	  margin-left: 0px !important;
	}
  
	.nav-item {
	  margin: 0 !important;
	}
  
	.navbar-nav .dropdown-menu {
	  padding: 10px 0 !important;
	  box-shadow: none !important;
	}
  
	.dropdown-item {
	  padding: 6px 15px !important;
	}
  
	.nav-item .nav-link {
	  padding: 0 !important;
	}
  
	.nav-item {
	  padding: 7.5px 0 !important;
	}
  
	header::before {
	  content: '' !important;
	  position: absolute !important;
	  top: 80px !important;
	  left: 0px !important;
	  width: 100vw !important;
	  height: 1px !important;
	  background-color: rgba(0, 0, 0, 0.06) !important;
	  z-index: 99999 !important;
	}
  
	header > .container {
	  width: auto !important;
	  max-width: unset !important;
	  padding: 0 !important;
	}
  }
  
  /* @media (max-width: 380px) {
	  .login-container {
		  width: 300px !important;
	  }
  }
  @media (max-width: 768px) {
	  .dropdown-item {
		  border-left: 5px solid #f4f4f4 !important;
	  }
	  .table,
	  .table .tbody,
	  .table .td,
	  .table .th,
	  .table .thead,
	  .table .tr {
		  display: block !important;
	  }
	  .table .tr {
		  padding: 1rem 1.2rem !important;
	  }
	  .table .tr .td {
		  padding: 0.5rem 0 !important;
		  text-align: left !important;
	  }
	  .table .tr .td:first-child {
		  padding-top: 0  !important;
	  }
	  .table .tr .td:last-child {
		  padding-bottom: 0  !important;
	  }
	  .table .thead {
		  padding: 0 !important;
	  }
	  .table .thead .tr {
		  position: absolute !important;
		  top: -9999px !important;
		  left: -9999px !important;
	  }
	  .table .td::before {
		  white-space: nowrap !important;
		  display: block !important;
		  content: attr(data-heading) !important;
		  line-height: 2 !important;
		  font-weight: 500 !important;
		  padding: 0 !important;
	  }
	  .pagination-select {
		  margin: 0 !important;
	  }
	  .pagination-select label {
		  margin-left: auto !important;
	  }
	  .pagination-select .form-select {
		  margin-right: auto !important;
	  }
  } */
  
  /* DARK MODE */
  
  body.dark,
  body.dark header,
  body.dark .dropdown-menu {
	background-color: var(--main-dark) !important;
	color: var(--text-dark) !important;
  }
  body.dark .profile-item .dropdown-menu .dropdown-item {
	background-color: var(--main-dark) !important;
  }
  body.dark .profile-item .dropdown-menu .dropdown-item:hover {
	background-color: var(--main-dark) !important;
  }
  
  body.dark .navbar-light .navbar-toggler-icon{
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M3 4h18v2H3V4zm0 7h18v2H3v-2zm0 7h18v2H3v-2z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E") !important;
  }
  body.dark .form-control {
	color: var(--gray-dark) !important;
  }
  
  body.dark .dropdown-item,
  body.dark .nav-link,
  body.dark .navbar-light .nav-link,
  body.dark .table {
	color: var(--text-dark) !important;
  }
  
  body.dark .dropdown-item:hover {
	color: var(--brand-color) !important;
  }
  
  body.dark .form-group input,
  body.dark .form-group textarea,
  body.dark .file-input-container > label {
	background-color: var(--secondary-dark) !important;
  }
  
  body.dark .form-control:focus,
  body.dark .form-control:active {
	background-color: var(--secondary-dark) !important;
  }
  
  body.dark .table .tr:nth-child(2n) {
	background-color: var(--secondary-dark) !important;
  }
  
  body.dark .table .tfoot {
	border-top: 2px solid var(--border-dark) !important;
  }
  
  body.dark .navbar-light .navbar-nav .nav-link:hover,
  body.dark .navbar-light .navbar-nav .nav-link:focus,
  body.dark .navbar-light .navbar-nav .show > .nav-link {
	color: var(--hover-dark) !important;
  }
  
  body.dark .navbar-light .navbar-nav .nav-link::before,
  body.dark header .profile-item .nav-link::before {
	background-color: var(--hover-dark) !important;
  }
  body.dark header {
	box-shadow: 0 1px 0 0 var(--border-dark) !important;
  }
  body.dark footer {
	box-shadow: 0 -1px 0 0 var(--border-dark) !important;
  }
  
  body.dark .breadcrumb-item a,
  body.dark .breadcrumb-item + .breadcrumb-item::before {
	color: var(--gray-dark) !important;
  }
  body.dark .breadcrumb-item:hover a {
	color: var(--brand-color) !important;
  }
  body.dark footer .copyright {
	color: var(--gray-dark) !important;
  }
  body.dark .breadcrumb-item.active a {
	color: var(--brand-color) !important;
  }
  
  body.dark .file-selected .label-remove {
	border-left: 1px solid var(--border-dark) !important;
  }
  /* body.dark button.btn-secondary {
	color: var(--text-dark) !important;
  } */
  /* body.dark button.btn-secondary:focus,
  body.dark button.btn-secondary:active,
  body.dark button.btn-secondary:hover {
	color: var(--text-dark) !important;
	box-shadow: 0 5px 10px 0 rgb(0, 0, 0, 0.2) !important;
  } */
  
  body.dark .form-csCheck-checkmark {
	background-color: var(--secondary-dark) !important;
	box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.03),
	  0 1px 3px 0 rgba(0, 0, 0, 0.1) !important;
  }
  
  body.dark .table-wrapper {
	border: 1px solid var(--border-dark) !important;
  }
  
  body.dark .form-select select {
	background: var(--secondary-dark) !important;
  }
  
  body.dark .pagination-select select {
	background-color: transparent !important;
  }
  .ui.active.modal {
	height: auto;
	top: auto;
	left: auto;
	bottom: auto;
	right: auto;
  }
  /* .table .tr .td:first-child {
	  min-width: 40% !important;
  } */
  body.dark .switch-text {
	color: var(--text-dark);
  }
  .sessionbtn {
	height: 60px !important;
	font-weight: 500 !important;
	padding: 11px 18px !important;
	border-radius: 8px !important;
	border: none !important;
	font-size: 14px !important;
	transition: all 0.3s ease !important;
	text-align: center;
  }
  .paymentin {
	color: green;
  }
  .paymentout {
	color: red;
  }
  .attendancesheet th,
  .attendancesheet td {
	border: 1px solid black !important;
	border-collapse: collapse !important;
  }
  
  .daily-attendance .thead > .tr > .td:first-child,
  .daily-attendance .tbody > .tr > .td:first-child,
  .attendance-admin .thead > .tr > .td:first-child,
  .attendance-admin .tbody > .tr > .td:first-child {
	max-width: 175px;
	padding-left: 14px !important;
  }
  
  .daily-attendance .nav-item,
  .attendance-admin .nav-item {
	padding: 0 !important;
  }
  .daily-attendance .nav-tabs,
  .attendance-admin .nav-tabs {
	border-bottom: none !important;
  }
  .daily-attendance .nav-link,
  .attendance-admin .nav-link {
	padding: 10px !important;
	min-width: 50px;
	text-align: center;
  }
  
  .daily-attendance .week-select,
  .attendance-admin .week-select {
	margin-bottom: 0 !important;
	margin-left: auto !important;
	width: 100px;
  }
  .daily-attendance .table .tr .td,
  .attendance-admin .table .tr .td {
	display: block;
  }
  .nav-tabs {
	border-bottom: none;
  }
  
  @media (max-width: 1024px) {
	.custom-nav .nav {
	  flex-direction: column;
	  width: 100%;
	}
	.custom-nav .nav-item,
	.custom-nav .nav-link {
	  width: 100%;
	}
	.custom-nav {
	  flex-direction: column;
	}
	.custom-nav > div {
	  order: -1;
	  margin-left: 0 !important;
	}
  }
  .custom-nav {
	border-bottom: 2px solid rgba(0, 0, 0, 0.06);
	margin-bottom: 20px;
  }
  .custom-nav .nav {
	border-bottom: none;
  }
  
  .custom-nav .nav-tabs .nav-item {
	margin: 0 !important;
	padding: 0 !important;
  }
  .custom-nav .nav-tabs .nav-item:first-child .nav-link {
	padding-left: 0 !important;
  }
  .custom-nav .nav-tabs .nav-link {
	cursor: pointer !important;
	padding: 15px !important;
	border: none;
	position: relative;
  }
  
  .custom-nav .nav-tabs .nav-link:hover,
  .custom-nav .nav-tabs .nav-link.active {
	border: none;
	position: relative;
  }
  .custom-nav .nav-tabs .nav-link::before {
	content: '';
	position: absolute;
	width: 100%;
	height: 2px;
	background-color: transparent;
	bottom: -2px;
	left: 0;
	transition: all 0.3s ease !important;
  }
  .custom-nav .nav-tabs .nav-link:hover::before,
  .custom-nav .nav-tabs .nav-link.active::before {
	background-color: var(--brand-color);
  }
  .custom-nav .week-select {
	min-width: 110px;
  }
  .week-select .form-select {
	width: 100%;
  }
  .add-student .course-tab {
	cursor: pointer;
	transition: color 0.2s ease;
	margin-bottom: 8px;
  }
  .add-student .course-tab.active {
	color: var(--brand-color);
  }
  .label-space {
	margin-top: 29px;
  }
  
  #studenttable td {
	min-height: 25px;
  }
  
  .add-student .react-datepicker-popper {
	min-width: 242px;
  }
  
  .add-student .form-select {
	min-width: 115px !important;
  }
  
  @media (max-width: 640px) {
	.add-student .react-datepicker-popper {
	  left: unset !important;
	  right: 0 !important;
	}
	.add-student .responsiveTable td.pivoted {
	  font-size: 12px !important;
	}
  }
  
  .popover {
	border: none !important;
  }
  .bs-popover-auto > .arrow::before {
	border-color: transparent;
  }
  .bs-popover-auto[x-placement^='bottom'] > .arrow::before,
  .bs-popover-bottom > .arrow::before {
	border-bottom-color: rgba(0, 0, 0, 0.017) !important;
  }
  .bs-popover-auto[x-placement^='right'] > .arrow::before,
  .bs-popover-right > .arrow::before {
	border-right-color: rgba(0, 0, 0, 0.017) !important;
  }
  .bs-popover-auto[x-placement^='left'] > .arrow::before,
  .bs-popover-left > .arrow::before {
	border-left-color: rgba(0, 0, 0, 0.017) !important;
  }
  .bs-popover-auto[x-placement^='top'] > .arrow::before,
  .bs-popover-top > .arrow::before {
	border-top-color: rgba(0, 0, 0, 0.017) !important;
  }
  
  .react-datepicker__triangle {
	display: none !important;
  }
  
  .react-datepicker__navigation {
	top: 21px !important;
  }
  .react-datepicker__current-month {
	margin-bottom: 10px !important;
  }
  
  .react-datepicker__month {
	margin-top: 0 !important;
  }
  .react-datepicker__day-name {
	font-weight: 500;
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
	font-size: 0.93rem !important;
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
	background-color: var(--brand-color) !important;
  }
  
  .popover-inner,
  .react-datepicker {
	border: none !important;
	padding: 10px 12px !important;
	border-radius: 8px;
	background-color: #ffffff;
	box-shadow: var(--main-shadow);
	font-size: 0.93rem;
  }
  .action-buttons {
	font-size: 18px;
  }
  .action-button {
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--text-gray-lighter) !important;
  }
  .action-button:first-child {
	color: var(--text-gray-light) !important;
  }
  .action-buttons > div {
	cursor: pointer;
  }
  
  .ri-spin {
	display: inline-block;
	animation-name: spin;
	animation-duration: 2000ms;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
  }
  .action-buttons .dropdown-menu {
	border-radius: 8px !important;
  }
  @keyframes spin {
	from {
	  transform: rotate(0deg);
	}
	to {
	  transform: rotate(360deg);
	}
  }
  
  .double-row {
	display: flex !important;
	flex-direction: column !important;
	align-items: flex-start !important;
  }
  .double-row .tr {
	padding-left: 0 !important;
	padding-right: 0 !important;
  }
  .double-row .tr:nth-child(2n) {
	background-color: transparent !important;
  }
  
  /* Alert toasters */
  
  .alertify-notifier.ajs-bottom.ajs-right {
	right: -20px !important;
  }
  
  .ajs-message {
	border-radius: 8px !important;
	padding: 10px !important;
	color: var(--text-dark) !important;
  }
  
  .ajs-message.ajs-error {
	background-color: var(--red) !important;
  }
  
  /* Loading */
  
  #root .loading {
	width: 100vw;
	height: 100vh;
	position: fixed !important;
	z-index: 5;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(255, 255, 255, 0.7);
  }
  
  .loading > i {
	font-size: 40px;
	color: var(--brand-color);
  }
  
  .half-opacity {
	pointer-events: none;
	opacity: 0.5;
  }
  
  .multi-select .dropdown-content {
	border: none !important;
	box-shadow: var(--main-shadow) !important;
	padding: 15px;
  }
  .multi-select .dropdown-content::-webkit-scrollbar {
	display: none !important;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .multi-select .dropdown-content {
	-ms-overflow-style: none !important; /* IE and Edge */
	scrollbar-width: none !important; /* Firefox */
  }
  .multi-select .select-panel > div {
	padding-left: 0 !important;
	padding-right: 0 !important;
  }
  
  .multi-select .select-panel > .select-item {
	font-weight: 500;
	padding-left: 0 !important;
	background-color: transparent !important;
  }
  .multi-select .select-panel > .select-item span {
	padding-left: 0 !important;
  }
  .multi-select .select-panel > .select-item::before {
	display: none;
  }
  .multi-select .select-panel > div > input {
	border: none !important;
  }
  .multi-select .select-panel > div > input:focus {
	box-shadow: 0 0 0 1px var(--brand-color) !important;
  }
  .multi-select label.select-item {
	position: relative;
	margin-bottom: 0;
	background-color: transparent !important;
  }
  .multi-select label.select-item::before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 3px;
	background-color: var(--light-gray-bg2);
	transition: all 0.3s ease;
  }
  .multi-select label.select-item:hover::before {
	background-color: var(--text-gray-lighter) !important;
  }
  .multi-select label.select-item[aria-selected='true']::before {
	background-color: var(--brand-color) !important;
  }
  .multi-select label.select-item input {
	display: none;
  }
  
  /* Switch  */
  .switch-container {
	display: flex;
	align-items: center;
  }
  .switch-text {
	margin-right: 9px;
	color: var(--text-gray-light);
  }
  
  .switch {
	position: relative;
	display: inline-block;
	width: 30px;
	height: 17px;
	margin-bottom: 0;
  }
  .recipe-switch {
	position: relative;
	display: inline-block;
	margin-bottom: 0;
  }
  
  .switch input {
	opacity: 0;
	width: 0;
	height: 0;
  }
  
  .profile-unlogin {
	width: 80px !important;
	height: 40px !important;
	border-style: solid;
	border-width: 1px;
	border-radius: 8px !important;
  
  }
  .login-text{
	font-weight: 900  !important;
	font-size: 14px !important;
  }
  .register-text{
	font-weight: 300 !important;
	font-size: 10px !important;
  }
  .slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ebebeb;
	transition: all 0.4s ease;
  }
  
  .slider:before {
	position: absolute;
	content: '';
	height: 11px;
	width: 11px;
	left: 3px;
	bottom: 3px;
	background-color: white;
	transition: all 0.4s ease;
	box-shadow: 0 3px 5px rgba(0, 0, 0, 0.08);
  }
  
  input:checked + .slider {
	background-color: #2cd798;
  }
  .baddiv {
	max-width: 100px !important;
	padding: 20px 20px !important;
  }
  input:checked + .slider:before {
	transform: translateX(11px);
	box-shadow: 0 3px 5px rgba(0, 0, 0, 0.16);
  }
  
  /* Rounded sliders */
  .slider.round {
	border-radius: 8px;
  }
  
  .slider.round:before {
	border-radius: 50%;
  }
  
  .mt-20 {
	margin-top: 20px;
  }
  @media (max-width: 1095px) {
	.days-row > .col {
	  width: 217px !important;
	}
  }
  
  .video-container {
    position:relative;
    padding-bottom:56.25%;
    padding-top:30px;
    height:0;
    overflow:hidden;
}

.video-container iframe, .video-container object, .video-container embed {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
}